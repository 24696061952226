import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../../App.css";
import { getStoredUser } from "../../pages/auth/user-storage";
import RoleResourceManagement from "../../pages/admin/SetupAdministration/RoleResourceManagement/RoleResourceManagement";

const { SubMenu } = Menu;
interface Step1Props {
  dashboardSidebar: Boolean;
  showMenu: () => void;
}


interface Step1Props {
  dashboardSidebar: Boolean;
  showMenu: () => void;
}

interface MenuItem {
  id: number;
  display: string;
  link: string;
  location: string;
  parent?: ParentMenuItem;
}

interface ParentMenuItem extends MenuItem {
  children?: MenuItem[];
}

const hasRoleOAGFAdmin = (roles) => {
  return roles?.some((role) => role?.id === "ROLE_OAGF_ADMIN");
};

const SideBar: React.FC<Step1Props> = ({ dashboardSidebar, showMenu }) => {
  const [activeMenu, setActiveMenu] = useState("");

  const authed = getStoredUser();

  const isOagf = hasRoleOAGFAdmin(authed?.organizationUser?.authorities);

  const navigate = useNavigate();
  const location = useLocation();

  function getFormattedDate() {
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];


    const today = new Date();
    const dayOfWeek = weekdays[today.getDay()];
    const dayOfMonth = today.getDate();
    const month = months[today.getMonth()];
    const year = today.getFullYear();

    return `${dayOfWeek}, ${dayOfMonth} ${month} ${year}`;
  }

  function getOrganisation() {
    const lazydayUser = JSON.parse(
      localStorage.getItem("lazyday_user") as string
    );
    return lazydayUser?.registeredName;
  }

   const lazydayUser = JSON.parse(
      localStorage.getItem("lazyday_user") as string
    );

  const activeStyle: any = {
    background: "#B4D1FF",
    borderRadius: "4px",
    gap: "10px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHight: "21px",
    color: "#050649",
  };

  // const activeStyleH: any = {
  //   marginTop: "6rem",
  //   background: "#3BAEFE",
  //   borderRadius: "4px",
  //   gap: "10px",
  //   fontFamily: "Poppins",
  //   fontStyle: "normal",
  //   fontWeight: 500,
  //   fontSize: "14px",
  //   lineHight: "21px",
  //   color: "#061B01",
  // };

  const generalStyle: any = {
    display: "grid",
    gap: "1rem",
    background: "#F2F2F2",
  };

  const subMenuStyle: any = {
    background: "#EBF6FE",
    borderRadius: "4px",
    // fontFamily: "Poppins",
    // fontStyle: "normal",
    // fontWeight: 500,
    // fontSize: "14px",
    // lineHight: "21px",
    // color: "#061B01",
  };

  const currentPath = location.pathname;

  const getParentMenuKey = (path: string) => {
    if (path.startsWith("/admin/setup-administration")) {
      return "dashboard";
    } else if (path.startsWith("/admin/collections")) {
      return "Collections";
    } else if (path.startsWith("/admin/payment")) {
      return "Payments";
    } else if (path.startsWith("/admin/reports")) {
      return "Reports";
    } else if (path.startsWith("/admin/analytics")) {
      return "Analytics";
    } else if (path.startsWith("/admin/mda-balance-statement")) {
      return "BalancesStatement";
    } else {
      return "admin"; // default parent menu key
    }
  };

  // Determine the parent menu key for the current route
  const currentParentMenuKey = getParentMenuKey(currentPath);

  // Set the active key and default selected keys
  const [activeKey, setActiveKey] = useState<string>(currentPath);
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>([
    currentParentMenuKey,
  ]);

  const handleClick = (e) => {
    if (e.length !== 0) {
      setActiveKey(e);
    }
  };

  useEffect(() => {
    // Set the default selected keys based on the parent menu key
    setDefaultSelectedKeys([currentParentMenuKey]);
  }, [currentParentMenuKey]);


    useEffect(() => {
    groupMenuItems();
  }, []);

  const groupMenuItems = (): ParentMenuItem[] => {
    const groupedItems: { [parentId: number]: ParentMenuItem } = {};

    const menuItems = lazydayUser?.organizationUser?.resource;
  
    

    menuItems?.forEach((item) => {
      if (item.parent) {
        const parentId = item.parent.id;
        if (!groupedItems[parentId]) {
          groupedItems[parentId] = { ...item.parent, children: [] };
        }

        groupedItems[parentId].children?.push(item);
      }
    });
    return Object.values(groupedItems);
  };

  return (
    <div
      className={
        dashboardSidebar ? "sidebarMain sidebarMainActivated" : "sidebarMain"
      }
    >
      <button className={"closeMobileMenu"} onClick={showMenu}>
        <i className={"fas fa-times"}></i>
      </button>
      <div className={"sidebarHeading"}>
        <div>{getFormattedDate()}</div>
        <button style={{ width: "fit-content" }}>
          <span style={{ margin: "1rem" }}>{getOrganisation()}</span>
        </button>
      </div>

      <nav>
        <Menu
          mode="inline"
          style={generalStyle}
          // defaultOpenKeys={[activeKey]}
          onOpenChange={handleClick}
          defaultSelectedKeys={defaultSelectedKeys}
          defaultOpenKeys={[currentParentMenuKey]}
        >
          <Menu.Item
            key="/admin"
            style={
              currentPath === "/admin" ||
              currentPath === "/admin/oagf-overview" ||
              currentPath === "/admin/mda-directory" ||
              currentPath.startsWith("/admin/reviewer") ||
              currentPath === "/admin/oagf-overview/service-activation"
                ? activeStyle
                : ""
            }
            icon={
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8125 7.5625H7.5625V11.8125H11.8125V7.5625Z"
                  stroke="#A655FF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.4375 7.5625H1.1875V11.8125H5.4375V7.5625Z"
                  stroke="#A655FF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.8125 1.1875H7.5625V5.4375H11.8125V1.1875Z"
                  stroke="#A655FF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.4375 1.1875H1.1875V5.4375H5.4375V1.1875Z"
                  stroke="#A655FF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          >
            {isOagf ? (
              <Link className="ps-0" to="/admin/oagf-overview">
                Overview
              </Link>
            ) : (
              <Link className="ps-0" to="/admin">
                Overview
              </Link>
            )}
          </Menu.Item>

                 <Menu.Item
            key="HelpSupport"
            style={currentPath === "HelpSupport" ? activeStyle : ""}
            icon={
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8125 7.5625H7.5625V11.8125H11.8125V7.5625Z"
                  stroke="#A655FF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.4375 7.5625H1.1875V11.8125H5.4375V7.5625Z"
                  stroke="#A655FF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.8125 1.1875H7.5625V5.4375H11.8125V1.1875Z"
                  stroke="#A655FF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.4375 1.1875H1.1875V5.4375H5.4375V1.1875Z"
                  stroke="#A655FF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          >
            <Link className="ps-0"  to="/admin/transactions">
              Transactions
            </Link>
          </Menu.Item>

          {groupMenuItems().map((parentItem: any) => {
            let parentList = parentItem;
            if (parentItem?.id && parentItem?.display !== "Reports OAGF") {
              // if (parentItem?.display === "Reports") {
              //   parentList = {
              //     ...parentList,
              //     children: [
              //       ...parentList?.children,
              //       {
              //         id: 28,
              //         display: "Payment Reports",
              //         link: "/admin/reports/paymentReport",
              //         location: "SIDEMENU",
              //         parent: {
              //           id: 27,
              //           display: "Reports",
              //           location: "SIDEMENU",
              //         },
              //       },
              //     ],
              //   };
              // }

              return (
                <>
                  <SubMenu
                    key={parentList?.display}
                    title={parentList?.display}
                    className="text-dark fs-8"
                    style={
                      parentList.children?.some((childItem) => {
                        return currentPath.startsWith(childItem.link);
                      })
                        ? subMenuStyle
                        : ""
                    }
                    icon={
                      <div className={"sidebarMenuHeading"}>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.1562 13.3437V2.71875C10.1562 2.43696 10.0443 2.16671 9.84505 1.96745C9.64579 1.76819 9.37554 1.65625 9.09375 1.65625H5.90625C5.62446 1.65625 5.35421 1.76819 5.15495 1.96745C4.95569 2.16671 4.84375 2.43696 4.84375 2.71875V13.3437M1.65625 3.78125H13.3437C13.6372 3.78125 13.875 4.0191 13.875 4.3125V12.8125C13.875 13.1059 13.6372 13.3437 13.3437 13.3437H1.65625C1.36285 13.3437 1.125 13.1059 1.125 12.8125V4.3125C1.125 4.0191 1.36285 3.78125 1.65625 3.78125Z"
                            stroke="#A655FF"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    }
                  >
                    {parentList.children?.map((childItem: any) => {
                      let childData = childItem;
                      // if (parentList?.display === "Reports") {
                      //   if (childItem.display === "Inflow") {
                      //     return;
                      //   }
                      //   if (childItem.display === "Outflow") {
                      //     return;
                      //   }
                      //   if (childItem.display === "Net Cash") {
                      //     return;
                      //   }
                      // }

                      return (
                        <Menu.Item
                          key={childData.id}
                          style={
                            currentPath.toLowerCase() ===
                              childData?.link.toLowerCase() ||
                            currentPath.startsWith(childData?.link)
                              ? activeStyle
                              : ""
                          }
                        >
                          <Link className="ps-0" to={childData?.link}>
                            {childData.display}
                          </Link>
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                </>
              );
            }
          })}

          <Menu.Item
            key="HelpSupport"
            style={currentPath === "HelpSupport" ? activeStyle : ""}
            icon={
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8125 7.5625H7.5625V11.8125H11.8125V7.5625Z"
                  stroke="#A655FF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.4375 7.5625H1.1875V11.8125H5.4375V7.5625Z"
                  stroke="#A655FF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.8125 1.1875H7.5625V5.4375H11.8125V1.1875Z"
                  stroke="#A655FF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.4375 1.1875H1.1875V5.4375H5.4375V1.1875Z"
                  stroke="#A655FF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          >
            <Link className="ps-0" to="">
              Help & Support
            </Link>
          </Menu.Item>
        </Menu>
      </nav>
    </div>
  );
};

export default SideBar;
