import { AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

// import type { User } from '../../../../../shared/types';
import {
  axiosInstance,
  axiosInstance2,
  axiosInstanceNameEnquire,
  getJWTHeader,
} from "../../../../axiosInstance";
import { SuccessToast } from "../../../componets/toast";
import { queryKeys } from "../../../react-query/constants";
import { clearStoredUser, getStoredUser, setStoredUser } from "../user-storage";
import { ErrorHandler } from "../../../../core/helpers/ErrorHandler";

async function requestSignin(values: any): Promise<any | null> {
  const { data, status }: AxiosResponse<any> = await axiosInstance({
    url: "api/authenticate",
    method: "POST",
    data: values,
    headers: { "Content-Type": "application/json" },
  });

  return { data, status };
}

async function getUser(user: any | null): Promise<any | null> {
  if (!user) return null;
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance.get(
      `/user/${user.id}`,
      {
        headers: getJWTHeader(user),
      }
  );
  return data.user;
}

export async function getUserBankDetails(): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
      `/services/organization/api/organizations/${orgId}/banks`
  );
  return data;
}

async function createService(values: any): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");
  //services/organization/api/organizations/${orgId}/services
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.post(
      `/services/organization/api/organizations/${orgId}/services`,
      values
  );
  return data;
}

async function getServiceDetails(serviceId: string): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
      `/services/organization/api/organizations/${orgId}/services/${serviceId}`
  );
  return data;
}

async function getService(response: any): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
      `/services/organization/api/organizations/${response.orgId}/services/${response.serviceId}`
  );
  return data;
}

async function getServiceDetailsWithOrgIdOagf(
    orgId: string,
    serviceId: string
): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
      `/services/organization/api/oagf/organizations/${orgId}/services/${serviceId}`
  );
  return data;
}

async function updateServiceDetails(
    serviceId: any,
    values: any
): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.patch(
      `/services/organization/api/organizations/${orgId}/services/${serviceId}`,
      values
  );
  return data;
}

async function getInactiveService(): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");
  const orgId: string | null = localStorage.getItem("orgId");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `/services/organization/api/organizations/find-services?status=false`
  );

  return data;
}

async function getAllService(): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");
  const orgId: string | null = localStorage.getItem("orgId");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `services/organization/api/organizations/find-all-services?size=100000`
  );

  return data;
}

export async function activeService(
    serviceId: any,
    orgId: any,
    revenueCode: any
): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");
  // const orgId: string | null = localStorage.getItem("orgId");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
      `services/organization/api/organizations/${orgId}/services/${serviceId}/activate-service`,
      revenueCode
  );
  return data;
}

export async function toggleService(serviceId: any): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");
  // const orgId: string | null = localStorage.getItem("orgId");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
      `services/organization/api/organizations/services/${serviceId}/toggle-service-visibility`
  );
  return data;
}

async function getResource(): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/api/resources`
  );
  return data;
}

async function getServices(): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
      `/services/organization/api/organizations/${orgId}/find-services`
  );
  return data;
}
async function getOrgUserFLow(): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
      `/services/organization/api/onboarding-step-three/organizations/${orgId}/workflows`
  );
  return data;
}

async function getMda(): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `/services/organization/api/onboarding-step-two/organizations`
  );
  return data;
}

async function getSector(): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
      `/services/organization/api/mda-sectors`
  );
  return data;
}

async function getUsersDetails(): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
      `/services/organization/api/organizations/${orgId}/users`
  );
  return data;
}

async function verificationSinglecode(values: any): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");

  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<any> = await axiosInstance.post(
      `/api/validate-otp`,

      values
  );

  return data;
}

async function verificationcode(values: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.post(
      `/api/onboarding-step-one/validate-otp-and-reset-password`,

      values
  );

  return data;
}





async function getUserRole(login: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/api/authorities/username/${login} `
  );

  return data;
}




async function editRoleUser(values): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.put(
    `/api/admin/users/${values.username}/authorities`,
    [
      {
        name: values.value,
      },
    ]
  );

  return data;
}

async function editRoleUser2(values): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.put(
    `/api/admin/users/${values.username}/authorities`,
   values.value,
  );

  return data;
}

async function CreateRoleUser(values): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
    `/api/admin/authorities`,
    {
      name: values,
    }
  );

  return data;
}

async function addResources(values): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
    `/api/resources/role`,
    values
  );

  return data;
}

async function editResources(values): Promise<any | null> {
  console.log(values);
  
  const { data }: AxiosResponse<any> = await axiosInstance2.put(
    `/api/resources/role/${values?.authority}`,
    values
  );

  return data;
}


async function resetPassword(values: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.post(
      `/api/account/reset-password/finish`,

      values
  );

  return data;
}

async function requestForgotPassword(values: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.post(
      `/api/account/reset-password/init`,
      values
  );

  return data;
}

async function requestResetPassword(values: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.post(
      `/api/account/reset-password/finish`,
      values
  );

  return data;
}

async function requestOnboardingStepOne(values: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.post(
      `/api/onboarding-step-one/personal-details`,
      values
  );

  return data;
}

async function requestOnboardingStepTwo(values: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.post(
      `/api/payment/onboarding/step/two`,
      values
  );

  return data;
}

async function requestOnboardingSendVerificatoinCode(
    values: any
): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.post(
      `/api/onboarding-step-one/send-verification-code?email=${values}`,
      values
  );

  return data;
}


async function getLogout(

): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.post(
      `/api/user/logout`,
      {}
  );

  return data;
}

async function requestOnboardingStepentitydetails(
    values: any
): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<any> = await axiosInstance2.post(
      `/services/organization/api/onboarding-step-two/organizations`,

      values
  );

  return data;
}

async function getUserList(value): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");



  const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `/services/organization/api/organizations/${orgId}/users?first-name=${value}&last-name=${value}&phone=${value}&email=${value}`,
  );

  return data;
}



async function getRoleList(): Promise<any | null> {

  const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `/api/authorities/non-role-admin`,
  );

  return data;
}



async function setUserRole(values): Promise<any | null> {


  const login: string | null = localStorage.getItem('login');


  const { data }: AxiosResponse<any> = await axiosInstance2.post(
      `/api/admin/users/${values.userLogin}/authorities`, values.role
  );

  return data;
}


async function editUser(values): Promise<any | null> {

  const orgId: string | null = localStorage.getItem("orgId");

  const login: string | null = localStorage.getItem('login');


  const { data }: AxiosResponse<any> = await axiosInstance2.put(
      `/services/organization/api/organizations/${orgId}/organization-users/${values.login}`, values.value
  );

  return data;
}




async function requestOnboardingStepBankDetails(
    values: any
): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<any> = await axiosInstance2.post(
      `/services/organization/api/onboarding-step-two/organizations/${orgId}/banks`,
      values
  );

  return data;
}

async function getBankInstitutions(): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<any> = await axiosInstance2.get(`/services/bankingservice/api/bankingservice/nip/institutions`);

  return data;
}

async function doAccountNameVerification(payload: any): Promise<any | null> {
  

  // const { data }: AxiosResponse<any> = await axiosInstanceNameEnquire.post(`/camel/api/name-enquiry`, payload);
  const { data }: AxiosResponse<any> = await axiosInstance2.post(`/services/bankingservice/api/bankingservice/nip/name-enquiry`, payload);

  return data;
}

async function requestOnboardingStepUserCreationDetails(
    values: any
): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const orgId: string | null = localStorage.getItem("orgId");
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
    `/services/organization/api/organizations/users`,
    values
  );
  return data;
}



async function requestOnboardingStepUserCreationDetailsNew(
    values: any
): Promise<any | null> {

  const { data }: AxiosResponse<any> = await axiosInstance2.post(
      `/services/organization/api/organizations/users`,
      values
  );






  //console.log(data, "eeeeeeeeeeee");

  return data;
}

async function requestOnboardingActivateUser(
    values: any
): Promise<any | null> {

  const { data }: AxiosResponse<any> = await axiosInstance2.post(
      `/api/resend-activation-key?email=${values}`,
      values
  );


  //console.log(data, "eeeeeeeeeeee");

  return data;
}



async function getRequestOnboarding(): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const orgId: string | null = localStorage.getItem("orgId");
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `/services/organization/api/onboarding-step-three/organizations/${orgId}/onboarding-data`
  );
  return data;
}

async function getProcessTypes(): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `/services/organization/api/request-types`
  );
  return data;
}

async function requestOnboardingStepUserWorkFlowProcess(
    values: any
): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
      `/services/organization/api/onboarding-step-three/organizations/${orgId}/workflows`,
      values
  );

  return data;
}

async function getMdaAuditTrail(filters: any): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");
  const token: string | null = localStorage.getItem("token");
  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `/services/auditservice/api/audit-trail/${orgId}/date/mda-trail?${filters}`
  );

  return data;
}

async function getOagfAuditTrail(filters: any): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");
  const token: string | null = localStorage.getItem("token");
  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `/services/auditservice/api/audit-trail/date/OAgf-trail?${filters}`
  );
  return data;
}

async function getMdaDetails(orgId: string | null): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `/services/organization/api/onboarding-step-three/organizations/${orgId}/onboarding-data`
  );
  return data;
}

async function activateMda(orgId: string | null): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
      `services/organization/api/onboarding-step-two/organizations/${orgId}/activate`
  );
  return data;
}

interface UseUser {
  user: any | null;
  updateUser: (user: any) => void;
  clearUser: () => void;
  isLoading: any;
}

export function useGetRequestOnboarding(): any {
  return useMutation(() => getRequestOnboarding(), {
    onSuccess: (res) => {
      SuccessToast("Your request was successful");
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useMdaDetails(): any {
  return useMutation((orgId: any) => getMdaDetails(orgId), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useMdaActivate(): any {
  return useMutation((orgId: any) => activateMda(orgId), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useUser(): UseUser {
  const queryClient = useQueryClient();

  const {
    data: user,
    isError,
    isLoading,
    error,
  } = useQuery(queryKeys.user, () => getUser(user), {
    initialData: getStoredUser,
    onSuccess: (recieved: any | null) => {
      if (!recieved) {
        clearStoredUser();
      } else {
        setStoredUser(recieved);
      }
    },
  });

  function updateUser(newUser: any): void {
    queryClient.setQueryData(queryKeys.user, newUser);
  }

  function clearUser() {
    queryClient.setQueriesData(queryKeys.user, null);
  }

  return { user, updateUser, clearUser, isLoading };
}

export function useRequestForgotPassword(): any {
  return useMutation((value) => requestForgotPassword(value), {
    onSuccess: (res) => {
      SuccessToast("Your request was successful");
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useRequestOnboardingStepOne(): any {
  return useMutation((value) => requestOnboardingStepOne(value), {
    onSuccess: (res) => {
      SuccessToast("Your request was successful");
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useRequestOnboardingStepTwo(): any {
  return useMutation((value) => requestOnboardingStepTwo(value), {
    onSuccess: (res) => {
      SuccessToast("Your request was successful");
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useRequestOnboardingSendVerificatoinCode(): any {
  return useMutation((value) => requestOnboardingSendVerificatoinCode(value), {
    onSuccess: (res) => {
      SuccessToast("Your request was successful");
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useRequestOnboardingStepentitydetails(): any {
  return useMutation((value) => requestOnboardingStepentitydetails(value), {
    onSuccess: (res) => {
      //console.log(res.id);

      localStorage.setItem("orgId", res.id);

      SuccessToast("Your request was successful");
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useRequestOnboardingStepBankDetails(): any {
  return useMutation((value) => requestOnboardingStepBankDetails(value), {
    onSuccess: (res) => {
      SuccessToast("Your request was successful");
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetBankInstitutions(): any {
  return useMutation(() => getBankInstitutions(), {
    onSuccess: (res) => {
      // SuccessToast("Your request was successful");
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useDoAccountNameVerification(): any {
  return useMutation((payload) => doAccountNameVerification(payload), {
    onSuccess: (res) => {
      SuccessToast("Name Enquiry is successful");
    },
    onError: (err: any) => {
    //  ErrorHandler(err);
    },
  });
}

export function useRequestOnboardingStepUserCreationDetails(): any {
  return useMutation(
      (value) => requestOnboardingStepUserCreationDetails(value),
      {
        onSuccess: (res) => {
          SuccessToast("Your request was successful");
        },
        onError: (err: any) => {
         ErrorHandler(err);
        },
      }
  );
}


export function useRequestOnboardingStepUserCreationDetailsNew(): any {
  return useMutation(
      (value) => requestOnboardingStepUserCreationDetailsNew(value),
      {
        onSuccess: (res) => {
          SuccessToast("Your request was successful");
        },
        onError: (err: any) => {
         ErrorHandler(err);
        },
      }
  );
}

export function useRequestActivateUser(): any {
  return useMutation(
      (value) => requestOnboardingActivateUser(value),
      {
        onSuccess: (res) => {
          SuccessToast("Your request was successful");
        },
        onError: (err: any) => {
         ErrorHandler(err);
        },
      }
  );
}

export function useRequestOnboardingStepUserWorkFlowProcess(): any {
  return useMutation(
      (value) => requestOnboardingStepUserWorkFlowProcess(value),
      {
        onSuccess: (res) => {
          SuccessToast("Your request was successful");
        },
        onError: (err: any) => {
         ErrorHandler(err);
        },
      }
  );
}

export function useVerificationcode(): any {
  return useMutation((value) => verificationcode(value), {
    onSuccess: (res) => {
      SuccessToast("Your request was successful");

      localStorage.setItem("token", res.data.token);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useVerificationSinglecode(): any {
  //verificationSinglecode
  return useMutation((value) => verificationSinglecode(value), {
    onSuccess: (res) => {
      SuccessToast("Your request was successful");

      // localStorage.setItem("token", res.data.token);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetUserBankDetails(): any {
  return useMutation(() => getUserBankDetails(), {
    onSuccess: (res) => {
      // toast.success('Your request was successful', {
      //   position: toast.POSITION.TOP_CENTER,
      //   className: 'fs-8',
      // });
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetOrgUserFLow(): any {
  return useMutation(() => getOrgUserFLow(), {
    onSuccess: (res) => {
      // toast.success('Your request was successful', {
      //   position: toast.POSITION.TOP_CENTER,
      //   className: 'fs-8',
      // });
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetSector(): any {
  return useMutation(() => getSector(), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetMdas(): any {
  return useMutation(() => getMda(), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetProcessTypes(): any {
  return useMutation(() => getProcessTypes(), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetResources(): any {
  return useMutation(() => getResource(), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}


export function useGetUsersDetails(): any {
  return useMutation(() => getUsersDetails(), {
    onSuccess: (res) => {
      // toast.success('Your request was successful', {
      //   position: toast.POSITION.TOP_CENTER,
      //   className: 'fs-8',
      // });
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useRequestSignin(): any {
  const { clearUser, updateUser } = useUser();
  const navigate = useNavigate();

  return useMutation((value) => requestSignin(value), {
    onSuccess: (res) => {
      SuccessToast("Your request was successful");

      // if (res.status === 200) {
      //   updateUser(res.data);

      //   localStorage.setItem("token", res.data.idToken);

      //   localStorage.setItem("orgId", res.data.id);

      //   navigate("/admin", { replace: true });
      // }
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useResetForgotPassword(): any {
  const navigate = useNavigate();

  return useMutation((value) => resetPassword(value), {
    onSuccess: (res) => {
      SuccessToast("Your request was successful");
      navigate("/auth/login");
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

//createService

export function useCreateService(): any {
  return useMutation((details: any) => createService(details), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

//get Service Details

export function useServiceDetails(): any {
  return useMutation((id: string) => getServiceDetails(id), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetService(): any {
  return useMutation((id: any) => getService(id), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

//getServiceDetailsWithOrgIdOagf
export function useServiceDetailsWithOrgId(): any {
  return useMutation(
      (data: { orgId: string; serviceId: string }) =>
          getServiceDetailsWithOrgIdOagf(data.orgId, data.serviceId),
      {
        onSuccess: (res) => {},
        onError: (err: any) => {
         ErrorHandler(err);
        },
      }
  );
}

//update or edit service

export function useUpdateService(): any {
  return useMutation(
      (data: any) => updateServiceDetails(data?.serviceId, data?.values),
      {
        onSuccess: (res) => {},
        onError: (err: any) => {
         ErrorHandler(err);
        },
      }
  );
}

//mda audit trail
export function useMdaTrail(): any {
  return useMutation((filter) => getMdaAuditTrail(filter), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

//oagf audit trail
export function useOagfTrail(): any {
  return useMutation((filter) => getOagfAuditTrail(filter), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useUserService(): any {
  const queryClient = useQueryClient();

  function updateService(newUser: any): void {
    queryClient.setQueryData(queryKeys.userService, newUser);
  }

  return { updateService };
}

// export function usegETsERVICE(): any {
//   return useMutation((value) => requestSignin(value), {
//     onSuccess: (res) => {
//       // useQueryClient().setQueryData("servieList", res);
//     },
//     onError: (err: any) => {
// if (err.response?.status === 401) {
//     // Handle the unauthorized error for this specific query
//     window.location.href = "/auth/login";
// }
//       let message = "";

//       if (err.response) {
//         const errMessage = err.response.data;
//         message = errMessage.message
//           ? errMessage.message
//           : "Something went wrong.";
//       } else {
//         message = err.message;
//       }

//       toast.error(message, {
//         position: toast.POSITION.TOP_CENTER,
//         className: "fs-8",
//       });
//     },
//   });
// }

export function useRequestgetServices(): any {
  const queryClient = useQueryClient();

  return useMutation(() => getServices(), {
    onSuccess: (res) => {
      queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

// export function useInactiveService(): any {
//   const queryClient = useQueryClient();

//   const {
//     data: inactiveService,
//     isError,
//     isLoading,
//     error,
//   } = useQuery(queryKeys.mdaList, () => getInactiveService());

//   return { inactiveService, isLoading, error };
// }

export function useInactiveService(): any {
  const queryClient = useQueryClient();

  return useMutation(() => getInactiveService(), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}



export function useAllService(): any {
  const queryClient = useQueryClient();

  return useMutation(() => getAllService(), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}


export function useGetLogOut(): any {

  return useMutation(() => getLogout(), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}


export function useGetUserList(): any {

  return useMutation((value) => getUserList(value), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}













export function useGetRoleList(): any {
  return useMutation(() => getRoleList(), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetUserRole(): any {
  return useMutation((values) => getUserRole(values), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useSetRole(): any {
  return useMutation((values) => setUserRole(values), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useEditUser(): any {
  return useMutation((values) => editUser(values), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useEditRole(): any {
  return useMutation((values) => editRoleUser(values), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useEditRole2(): any {
  return useMutation((values) => editRoleUser2(values), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useCreateRole(): any {
  return useMutation((values) => CreateRoleUser(values), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useAddResources(): any {
  return useMutation((values) => addResources(values), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}


export function useEditResources(): any {
  return useMutation((values) => editResources(values), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useRequestGetServices(): any {
  const queryClient = useQueryClient();

  return useMutation(() => getServices(), {
    onSuccess: (res) => {
      queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useActivateUser(): any {
  async function activateMda(payload): Promise<any | null> {
    const token: string | null = ("token");

    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const { data }: AxiosResponse<any> = await axiosInstance.post(
      `/api/admin/users/${payload.login}/activate/${payload.isActive}`
    );
    return data;
  }
  return useMutation((payload) => activateMda(payload), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}


export function useGetAllUserList(): any {
  async function getAllUserList(value): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `/services/organization/api/organizations-users${
        value && `?name=${value}`
      }`
    );

    return data;
  }

  return useMutation((value) => getAllUserList(value), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}




